import React from 'react';
import './client-carousel.scss'
import kmmlLogo from "../../assets/images/clients/kmml.jpg"
import adtyBirla from "../../assets/images/clients/adtybrlgrp.png"
import arvind from "../../assets/images/clients/arvind.png"
import borosil from "../../assets/images/clients/borosil.png"
import capgemini from "../../assets/images/clients/capgemini.png"
import duroflex from "../../assets/images/clients/duroflex.png"
import indoauto from "../../assets/images/clients/indoauto.png"
import jspl from "../../assets/images/clients/jspl.png"
import pidilite from "../../assets/images/clients/pidilite.png"
import stanzen from "../../assets/images/clients/stanzen.png"
import tytklskr from "../../assets/images/clients/tytklskr.jpg"
import vecv from "../../assets/images/clients/vecv.jpg"
import welspun from "../../assets/images/clients/welspun.jpg"

const ClientLg = ({ image, parentClass, imageWidth, wrapperStyle }) => {
  return (
    <div className="client-logo-container col-md-6 col-lg-3 align-self-center" style={wrapperStyle}>
      <img
        width={imageWidth}
        height="auto"
        src={image}
        className="img-fluid img-responsive"
      />
    </div>
  )
}

const slides = [
  [
    {
      image: borosil,
      wrapperStyle: {
        flex: '0 0 23%'
      }
    },
    {
      image: jspl,
      wrapperStyle: {
        flex: '0 0 23%'
      }
    },
    {
      image: welspun,
      wrapperStyle: {
        flex: '0 0 23%'
      }
    },
    {
      image: arvind,
      wrapperStyle: {
        flex: '0 0 23%'
      }
    }
  ],
  [
    {
      image: pidilite,
      wrapperStyle: {
        flex: '0 0 calc(28% - 6%)'
      }
    },
    {
      image: duroflex,
      wrapperStyle: {
        flex: '0 0 22%'
      }
    },
    {
      image: kmmlLogo,
      wrapperStyle: {
        flex: '0 0 22%'
      }
    },
    {
      image: indoauto,
      wrapperStyle: {
        flex: '0 0 calc(28% - 6%)'
      }
    }
  ],
  [
    {
      image: tytklskr,
      wrapperStyle: {
        flex: '0 0 22%'
      }
    },
    {
      image: vecv,
      wrapperStyle: {
        flex: '0 0 22%'
      }
    },
    {
      image: stanzen,
      wrapperStyle: {
        flex: '0 0 calc(28% - 6%)'
      }
    }
  ]
]

export default function ClientCarousel() {
  return (
    <section className="container client-carousel py-5 mb-5">
      <div className="clients-section">
        <h2 className="home-side-heading pt-5  p-top-choose">
          Our Clients
          </h2>
        <div
          id="carouselClientIndicators"
          className="carousel slide pt-lg-4 pt-md-0 d-none d-lg-block"
          data-ride="carousel"
          data-interval="10000"
        >
          <ol className="carousel-indicators">
            {
              slides.map((slide, index) => {
                return <li
                  data-target="#carouselClientIndicators"
                  data-slide-to={index}
                  className={index === 0 ? 'active' : ''}
                ></li>
              })
            }
          </ol>
          <div className="carousel-inner py-4">

            {
              slides.map((slide, index) => {
                return <div className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                  <div className="row m-0 align-items-center justify-content-between slide-lg">
                    {
                      slide.map(client => {
                        return <ClientLg
                          image={client.image}
                          wrapperStyle={client.wrapperStyle}
                        />
                      })
                    }
                  </div>
                </div>
              })
            }
          </div>
        </div>
        <div
          id="carouselClientIndicators1"
          className="carousel slide pt-3 d-block d-lg-none"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carouselClientIndicators1"
              data-slide-to="0"
              className="active"
            ></li>
            <li
              data-target="#carouselClientIndicators1"
              data-slide-to="1"
            ></li>
            <li
              data-target="#carouselClientIndicators1"
              data-slide-to="2"
            ></li>
            <li
              data-target="#carouselClientIndicators1"
              data-slide-to="3"
            ></li>
            <li
              data-target="#carouselClientIndicators1"
              data-slide-to="4"
            ></li>
            <li
              data-target="#carouselClientIndicators1"
              data-slide-to="5"
            ></li>
          </ol>
          <div className="carousel-inner clientImg py-4">
            <div className="carousel-item active">
              <div className="d-flex row pl-2">
                <div className="col-6 text-center">
                  <img
                    src={borosil}
                    width="180px"
                    className="img-fluid"
                  />
                </div>
                <div className="col-6 text-center">
                  <img
                    src={arvind}
                    width="180px"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="d-flex row pl-2">
                <div className="col-6 text-center">
                  <img
                    src={kmmlLogo}
                    width="180px"
                    className="img-fluid"
                  />
                </div>
                <div className="col-6 text-center">
                  <img
                    src={jspl}
                    width="180px"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="d-flex row pl-2">
                <div className="col-6 text-center">
                  <img
                    src={stanzen}
                    width="180px"
                    className="img-fluid"
                  />
                </div>
                <div className="col-6 text-center">
                  <img
                    src={pidilite}
                    width="180px"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="d-flex row pl-2">
                <div className="col-6 text-center">
                  <img
                    src={tytklskr}
                    width="100px"
                    className="img-fluid"
                  />
                </div>
                <div className="col-6 text-center">
                  <img
                    src={adtyBirla}
                    width="100px"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="d-flex row pl-2">
                <div className="col-6 text-center">
                  <img
                    src={capgemini}
                    width="180px"
                    className="img-fluid"
                  />
                </div>
                <div className="col-6 text-center">
                  <img
                    src={vecv}
                    width="180px"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="d-flex row pl-2">
                <div className="col-6 text-center">
                  <img
                    src={indoauto}
                    width="180px"
                    className="img-fluid"
                  />
                </div>
                <div className="col-6 text-center">
                  <img
                    src={duroflex}
                    width="180px"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
