import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/Contact"
import { Link } from "gatsby"
import { homePageFetaure, featureHighlights } from "../config"
import HomeSolutions from "../components/HomeSolutions"
import $ from "jquery"
import { navigate } from "gatsby"
import ChooseUsCarousel from "../components/home/choose-carousel"
import ClientCarousel from "../components/home/client-carousel"
import readmoreImg from "../assets/images/readmoreImg.svg"
import readmoreblue from "../assets/images/readmoreblue.svg"
import brewsightLogo from "../assets/images/brewsight.png"
import ijunctionLogo from "../assets/images/i-junction.png"
import ohmLogo from "../assets/images/ohm.png"
import letsTalk from "../assets/images/lets_talk.png"

const channelPartner = [
  {
    image: ijunctionLogo,
    address: 'iJunction, Raipur'
  },
  {
    image: brewsightLogo,
    address: 'Brewsight, Ahmedabad'
  },
  {
    image: ohmLogo,
    address: 'Ohm Technologies, Bangalore'
  }
]

const fetureCards = homePageFetaure.map((feature, index) => {
  const prefixClassName = index === 2 ? "feature-name" : "feature-value"
  return (
    <div
      style={{ fontFamily: "Avenir Roman" }}
      className="feature-outer-div col-lg-3 col-md-6 col-6 p-0 home-feature"
      key={feature.name}
    >
      <div className="feature-card-value">
        <span className={prefixClassName}>{feature.prefix}</span>
        <div
          className="feature-value counter d-inline text-center"
          data-target={feature.value}
        >
          0
        </div>
        <span className="feature-value">{feature.unit}</span>
      </div>
      <div className="feature-name p-0">{feature.name}</div>
    </div>
  )
})

// const clients = ourClients.map(client => (
//   <div className="col-md-4 col-lg-3 py-2 text-center" key={client.clientName}>
//     <img
//       className="img-fluid"
//       src={"/static/client logos/" + client.logoSrc}
//       alt={client.clientName}
//     />
//   </div>
// ))

const features = featureHighlights.map(feature => (
  <HomeSolutions key={feature.featureName} feature={feature} />
))

class IndexPage extends Component {
  state = {
    sentToLetstalk: false,
  }


  componentDidMount() {

    //number and solution animation
    const counters = document.querySelectorAll(".counter")
    const speed = 100 // The lower the slower

    $(document).ready(function () {
      $(window).scroll(function () {
        var positiontop = $(document).scrollTop()
        //viewport
        if (positiontop > 135 && positiontop < 200) {
          counters.forEach(counter => {
            const updateCount = () => {
              const target = +counter.getAttribute("data-target")
              const count = +counter.innerText
              // Lower inc to slow and higher to slow
              const inc = 1
              // Check if target is reached
              if (count < target) {
                // Add inc to count and output in counter
                const newtext = count + inc
                // console.log(count, inc, target)
                //console.log("newText", newtext);
                counter.innerText = Math.round(newtext)
                // Call function every ms
                setTimeout(updateCount, 1)
              } else {
                console.log(target)
                counter.innerText = target
              }
            }
            updateCount()
          })
        }
        // if (positiontop > 900 && positiontop < 2100) {
        //   $(".solutions-edge").addClass("solutions1")
        //   $(".solutions-edge").removeClass("sol-hide")
        //   $(".solutions-eagle").addClass("sol-hide")
        // }
        // if (positiontop > 2100 && positiontop < 2600) {
        //   $(".solutions-eagle").removeClass("sol-hide")
        //   $(".solutions-eagle").addClass("solutions2")
        //   $(".solutions-edge").addClass("sol-hide")
        //   $(".solutions-acute").addClass("sol-hide")
        // }
        // if (positiontop > 2600 && positiontop < 3000) {
        //   $(".solutions-acute").removeClass("sol-hide")
        //   $(".solutions-acute").addClass("solutions3")
        //   $(".solutions-eagle").addClass("sol-hide")
        // }
      })
    })

    $("#carouselClientIndicators").carousel({
      interval: 2500,
    })

    $("#carouselExampleIndicators").carousel({
      interval: 3000,
      cycle: true,
    })
    // this.simulateTypedAction()
  }

  simulateTypedAction() {
    const typedTextSpan = document.querySelector(".typed-text")
    const cursorSpan = document.querySelector(".cursor")

    const textArray = [
      "Identify Machine Failure Patterns and Hidden Correlations using Artificial Intelligence.",
      "We Help You Monitor Factory Conditions Remotely In Real-Time.",
      "We Help you Become a Leader in Industrial Digital Transformation.",
    ]
    const typingDelay = 40
    const erasingDelay = 10
    const newTextDelay = 2000 // Delay between current and next text
    let textArrayIndex = 0
    let charIndex = 0

    function type() {
      if (charIndex < textArray[textArrayIndex].length) {
        if (!cursorSpan.classList.contains("typing"))
          cursorSpan.classList.add("typing")
        typedTextSpan.textContent += textArray[textArrayIndex].charAt(charIndex)
        charIndex++
        setTimeout(type, typingDelay)
      } else {
        cursorSpan.classList.remove("typing")
        setTimeout(erase, newTextDelay)
      }
    }

    function erase() {
      if (charIndex > 0) {
        if (!cursorSpan.classList.contains("typing"))
          cursorSpan.classList.add("typing")
        typedTextSpan.textContent = ""
        charIndex = 0
        setTimeout(erase, erasingDelay)
      } else {
        cursorSpan.classList.remove("typing")
        textArrayIndex++
        if (textArrayIndex >= textArray.length) textArrayIndex = 0
        setTimeout(type, typingDelay)
      }
    }


    if (textArray.length) setTimeout(type, newTextDelay + 250)
  }

  // componentWillUnmount() { }

  requestDemoHandler = () => {
    console.log("request demo handler")
    navigate("/#lets-talk", { state: { sentToLetstalk: true } })
  }

  solutionHandler = () => {
    navigate("/#our-solutions", { state: { sentToLetstalk: true } })
  }

  render() {
    return (
      <Layout>
        <SEO title="Industrial AI Platform and Smart Factory Solutions for Digital Transformation | Machstatz" description="Machstatz deals with the digital transformation of enterprises. It provides an Industrial AI platform for instant connectivity across machines, delivers insights, and helps achieve business outcomes across manufacturing."/>
        <div className="text-center pt-lg-0 pt-md-3">
          <div className="container">
            <div className="row p-top-fold m-0">
              <div className="col-lg-6 col-md-12 col-sm-12 my-auto p-0">
                <div className="" id="output">
                  {/* <h1
                    className="cursor cursor-text"
                    style={{ fontFamily: "Avenir Roman" }}
                  >
                    <span className="typed-text"></span>
                    <span className="cursor">&nbsp;</span>
                  </h1> */}
                  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="6000">
                    <ol class="carousel-indicators" style={{bottom:'37px'}}>
                      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    </ol>
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <h1 className="cursor cursor-text" style={{ fontFamily: "Avenir Roman" }}>Implement Industrial IoT  Solutions and identify Machine Failure patterns using Artificial intelligence.</h1>
                      </div>
                      <div class="carousel-item">
                      <h1 className="cursor cursor-text" style={{ fontFamily: "Avenir Roman" }}>Monitor your Smart Factory Remotely using our platform.</h1>
                      </div>
                      <div class="carousel-item">
                      <h1 className="cursor cursor-text" style={{ fontFamily: "Avenir Roman" }}>We Help you Become a Leader in Industrial Digital Transformation.</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row demo-button m-0">
                  <div className="col-4 p-0 m-0">
                    <button
                      type="button"
                      className="d-none d-lg-block solution-button"
                      onClick={this.solutionHandler}
                    >
                      Our Solutions
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 p-0">
                <img
                  src="/static/svgs/Artboard 49.svg"
                  className="fold-img mt-md-4"
                ></img>
              </div>
            </div>
          </div>
          <div className="home-vr-main">
            <div className="home-vr">
              <span></span>
            </div>
          </div>
        </div>
        <div className="">
          <section className="container ">
            <div className="row py-4 m-0 full-width">{fetureCards}</div>
          </section>
          <ClientCarousel />
        </div>

        <div className="container d-flex flex-column py-5 mt-3">
          <h4 className="sub-title-about-us">Channel Partners</h4>
          <div className="row align-items-center py-5">
            {channelPartner.map(item => {
              return (
                <div className="col-12 col-sm-6 col-md-4  partners-img">
                  <div style={{ marginBottom: '10px' }}>
                    <img
                      src={item.image}
                      className="responsive-img-partners"
                    />
                  </div>
                  <p style={{ fontSize: '15px', width: '50%', margin: '0 auto' }}>{item.address}</p>
                </div>
              )
            })}
          </div>
        </div>
        <section className="container" id="lets-talk">
          <div className="row align-items-center pt-lg-5 pt-md-2 m-0">
            <div className="col-lg-7 col-md-12 order-lg-2">
              <img
                src={letsTalk}
                alt="Lets Talk"
                className="img-fluid talk-img"
              />
            </div>
            <div className="col-lg-5 col-md-12 pl-md-2 order-lg-1">
              <h3 className="home-side-heading-talk pt-5">Let’s Talk</h3>
              <section className="talk-form">
                <Contact />
              </section>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default IndexPage
